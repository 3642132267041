import { AxiosResponse } from 'axios';
import { RuleRowType } from '../components/QueryBuilder/RuleRow';
import { http } from './http';

export type Order = {
  id: string;
  remote_id: string;
  datestamp: Date;
  status: string;
  subtotal: number;
  tax: number;
  shipping: number;
  discount: number;
  credits: number;
  refund: number;
  total: number;
  address: string;
  address_2: string;
  birthday: Date;
  city: string;
  company: string;
  email: string;
  first_name: string;
  last_name: string;
  item_count: number;
  order_type: string;
  payment_status: string;
  phone: string;
  shipping_address: string;
  shipping_address_2: string;
  shipping_city: string;
  shipping_company: string;
  shipping_first_name: string;
  shipping_id: string;
  shipping_last_name: string;
  shipping_method: string;
  shipping_method_carrier: string;
  shipping_method_code: string;
  shipping_method_name: string;
  shipping_phone: string;
  shipping_state: string;
  shipping_title: string;
  shipping_zip: string;
  ship_status: string;
  state: string;
  tax_rate: number;
  transaction_id: string;
  zip: string;
};

export type OrderResData = {
  data: Order[];
  total: number;
  pageIndex: number;
  pageSize: number;
  sortIndex: number;
  sortType: number;
  isExport?: boolean;
};

export async function getOrderById(id: string): Promise<Order> {
  try {
    const { data } = await http.get<Order>(`/api/v1/orders/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

// These services use our own IL api
export async function getOrdersByRuleGroups(
  rg: RuleRowType[][],
  accountId?: string,
  page: {
    pageSize: number;
    pageIndex: number;
  } = {
    pageSize: 50,
    pageIndex: 1,
  },
  sortIndex?: number,
  sortType?: number,
  isExport = false,
): Promise<OrderResData> {
  try {
    const { data } = await http.post<
      {
        ruleGroups: RuleRowType[][];
        isExport?: boolean;
      },
      AxiosResponse<OrderResData>
    >(`/api/v1/orders/filter/${accountId}/${sortIndex ?? 0}/${sortType ?? 0}`, {
      ruleGroups: rg,
      ...page,
      isExport,
    });

    return data;
  } catch (error) {
    throw error;
  }
}

export async function pullOrdersFromOffset(accountId?: string): Promise<void> {
  try {
    await http.get<Order[]>(
      `/api/v1/orders/api-remote/get-orders-last-update${
        accountId ? `?account_id=${accountId}` : ''
      }`,
    );
  } catch (error) {
    throw error;
  }
}

export async function getShipStatusList(accountId?: string): Promise<string[]> {
  try {
    const { data } = await http.get<string[]>(
      `/api/v1/orders/ship-status-list/${accountId}`,
    );

    return data;
  } catch (error) {
    throw error;
  }
}
