import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import {
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
  Button,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useToken } from '../../services/auth';
import { EntitiesEnum } from '../../services/queryBuilder';
import {
  QueryData,
  query,
  QueryResult,
  getTestQueries,
  dealWithQueryResult,
} from '../../services/nlQuery';
import { FaCheck, FaTimes, FaRegPlayCircle } from 'react-icons/fa';
import NavBar from '../../components/NavBar/NavBar';
import { PAGE_INDEX_TESTS } from '../../utils/constants';
import { useHistory } from 'react-router-dom';

type TestConsoleProps = {
  entityType: EntitiesEnum | null;
  signOut: () => void;
  setPageIndex: (pageIndex: number) => void;
};

const TestConsole = ({
  entityType,
  signOut,
  setPageIndex,
}: TestConsoleProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingId, setIsLoadingId] = useState<string>('');
  const [customerQueries, setCustomerQueries] = useState<QueryData[]>([]);
  const [orderQueries, setOrderQueries] = useState<QueryData[]>([]);
  const [productQueries, setProductQueries] = useState<QueryData[]>([]);
  const [feedbackQueries, setFeedbackQueries] = useState<QueryData[]>([]);
  const [hasReportingToken, setHasReportingToken] = useState<boolean>(true);

  const toast = useToast();
  const history = useHistory();
  const { userInfo, setToken } = useToken();
  const models = [
    { label: 'GPT-3.5 Fastest model. Great for everyday queries.', value: '3' },
    { label: 'GPT-4o Most capable. Good for advanced queries.', value: '4' },
  ];
  const [selectedModel, setSelectedModel] = useState<string>();

  const judgeExpired = () => {
    let isExpired = true;
    const loginTimeStr = localStorage.getItem('login_time');
    if (loginTimeStr && loginTimeStr.length > 0) {
      const loginTime = Number.parseInt(loginTimeStr);
      const currentTime = new Date().getTime();
      isExpired = currentTime - loginTime > 3 * 24 * 3600 * 1000;
    }
    if (isExpired) {
      signOut();
    }

    return isExpired;
  };

  const loadQueries = useCallback(async () => {
    try {
      setIsLoading(true);
      setCustomerQueries([
        {
          id: '1',
          query_text: 'list customers who is from california',
          corrected_text: 'customer.state == "CA"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '2',
          query_text: 'list customers who bought red wine',
          corrected_text: 'product.wine_type == "Red Wine"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '3',
          query_text: 'customers from nevada and nickname has sh',
          corrected_text:
            'customer.state == "NV" && customer.nickname contains("sh")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '4',
          query_text: 'list customers whose state is california',
          corrected_text: 'customer.state == "CA"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '5',
          query_text: 'customers from california or montana',
          corrected_text: '(customer.state == "CA" || customer.state == "MT")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '6',
          query_text:
            'customers starts with sh and ends with on or order state is california',
          corrected_text:
            '(customer.first_name startsWith("sh") || order.state == "CA") && (customer.last_name endsWith("on") || order.state == "CA")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '7',
          query_text:
            'customers state is california and city is Los Angeles or product wine type is red',
          corrected_text:
            '(customer.state == "CA" || product.wine_type == "Red Wine") && (customer.city == "Los Angeles" || product.wine_type == "Red Wine")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '8',
          query_text:
            'customers from nevada and nickname has sh or status is Active',
          corrected_text:
            '(customer.state == "NV" || product.status == "Active") && customer.nickname contains("sh") && product.status == "Active"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '9',
          query_text: 'customers mailing state is california',
          corrected_text: 'customer.mailing_state == "CA"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '10',
          query_text: 'list customers whose mailing city is Los Angeles',
          corrected_text: 'customer.mailing_city == "Los Angeles"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '11',
          query_text: 'list customers whose mailing address contains mountains',
          corrected_text: 'customer.mailing_address contains("mountains")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '12',
          query_text: 'list customers who bought 2017 Chardonnay',
          corrected_text: 'product.name contains("2017 Chardonnay")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '13',
          query_text:
            'show me a list of customers who purchase 2018 cabernet but not 2020 cabernet',
          corrected_text:
            'product.name contains("2018 Cabernet") && product.name not contains("2020 Cabernet")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '14',
          query_text:
            'customer name is Jason and product country is US or order city starts with w',
          corrected_text:
            '(customer.first_name == "Jason" || order.city startsWith("W")) && (product.country == "US" || order.city startsWith("W"))',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '15',
          query_text:
            "show me anyone who signed up 3 months but hasn't yet purchased",
          corrected_text:
            'customer.created between("2023-07-11", "2023-11-11") && customer.last_order == null',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
      ]);
      setOrderQueries([
        {
          id: '21',
          query_text: 'list order total over $500 but under $2000',
          corrected_text: 'order.total between(500, 2000)',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '22',
          query_text: 'list orders from Los Angeles',
          corrected_text:
            'order.state == "CA" && order.city == "Los Angeles";order.city == "Los Angeles"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '23',
          query_text: 'order item count less than 10',
          corrected_text: 'order.item_count < 10',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '24',
          query_text: 'list products from America',
          corrected_text: 'product.country == "US"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '25',
          query_text:
            'order state is ak and product wine type is red or customer name starts with Ja',
          corrected_text:
            '(order.state == "AK" || customer.first_name startsWith("Ja")) && (product.wine_type == "Red Wine" || customer.first_name startsWith("Ja"))',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
      ]);
      setProductQueries([
        {
          id: '41',
          query_text: 'product wine type is white and status is Active',
          corrected_text:
            'product.wine_type == "White Wine" && product.status == "Active"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '42',
          query_text: 'find wines whose name is 2015 Merlot',
          corrected_text: 'product.name contains("2015 Merlot")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '43',
          query_text: 'find wines from USA',
          corrected_text:
            'product.country == "US";product.country contains("US")',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
        {
          id: '44',
          query_text:
            'product wine type is red and status is Active and country is US',
          corrected_text:
            'product.wine_type == "Red Wine" && product.status == "Active" && product.country == "US"',
          result_text: '',
          is_pass: -1,
          entity_type: EntitiesEnum.CUSTOMERS,
          account_id: userInfo?.currentAccount?.id,
        },
      ]);
      if (!entityType) {
        setFeedbackQueries(await getTestQueries(userInfo?.currentAccount?.id));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [userInfo?.currentAccount?.id, toast, entityType]);

  const handleRunQuery = async (queryData: QueryData) => {
    if (isLoadingId.length == 0) {
      if (!selectedModel) {
        toast({
          title: 'Please select GPT Model.',
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: false,
        });
        return;
      }
      try {
        localStorage.setItem('model', selectedModel);
        queryData.model = selectedModel;
        queryData.result_text = '';
        queryData.is_pass = -1;
        setIsLoadingId(queryData.id);

        const queryReturn = await query(
          queryData.entity_type,
          queryData.query_text,
          selectedModel,
          true,
          entityType ? '0' : queryData.id,
          userInfo?.currentAccount?.id,
        );
        dealWithQuery(queryData, queryReturn);
      } catch (error) {
        queryData.is_pass = 0;
        toast({
          title: 'Natural Language Query failed.',
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: false,
        });
      } finally {
        setIsLoadingId('');
      }
    }
  };

  const handleRunAllQueries = async () => {
    if (isLoadingId.length == 0) {
      if (!selectedModel) {
        toast({
          title: 'Please select GPT Model.',
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: false,
        });
        return;
      }
      let queries: QueryData[];
      if (entityType == EntitiesEnum.CUSTOMERS) {
        queries = customerQueries;
      } else if (entityType == EntitiesEnum.ORDERS) {
        queries = orderQueries;
      } else if (entityType == EntitiesEnum.PRODUCTS) {
        queries = productQueries;
      } else {
        queries = feedbackQueries;
      }
      localStorage.setItem('model', selectedModel);

      if (queries.length > 0) {
        queries.forEach((q) => {
          q.model = selectedModel;
          q.result_text = '';
          q.is_pass = -1;
        });

        let index = 0;
        while (index < queries.length) {
          const q = queries[index];
          try {
            setIsLoadingId(q.id);

            const queryReturn = await query(
              q.entity_type,
              q.query_text,
              selectedModel,
              true,
              entityType ? '0' : q.id,
              userInfo?.currentAccount?.id,
            );
            dealWithQuery(q, queryReturn);
          } catch (error) {
            q.is_pass = 0;
            toast({
              title: 'Natural Language Query failed.',
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: false,
            });
          } finally {
            setIsLoadingId('');
            index++;
          }
        }
      }
    }
  };

  const dealWithQuery = (queryData: QueryData, queryReturn: QueryData) => {
    let queryResult: QueryResult | null = null;
    if (queryReturn.result_text) {
      queryResult = JSON.parse(queryReturn.result_text);
    }
    if (queryResult) {
      const result = dealWithQueryResult(queryResult);
      queryData.model = queryReturn.model;
      queryData.result_text = result;
      if (result.length > 0) {
        let pass = 0;
        queryData.corrected_text?.split(';').forEach((e) => {
          if (result == e) {
            pass = 1;
          }
        });
        queryData.is_pass = pass;
      } else {
        queryData.is_pass = 0;
        toast({
          title: 'Natural Language Query failed.',
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: false,
        });
      }
    } else {
      queryData.is_pass = 0;
      toast({
        title: 'Natural Language Query failed.',
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
    }
  };

  useEffect(() => {
    if (!judgeExpired()) {
      // if (
      //   userInfo &&
      //   (userInfo?.profile.email.endsWith('@offsetpartners.com') ||
      //     userInfo?.profile.email.endsWith('@interactivelabs.co'))
      // ) {
      //   setPageIndex(PAGE_INDEX_TESTS);
      //   const localModel = localStorage.getItem('model');
      //   setSelectedModel(localModel ? localModel : '');
      //   loadQueries();
      // } else {
      history.push(`/home`);
      // }
    }
  }, [loadQueries]);

  return (
    <Flex direction={'column'} height={'100%'} pr={'30px'}>
      <NavBar
        removeToken={() => signOut()}
        entityType={entityType}
        paramName={'tests'}
      />
      <Flex
        direction={'column'}
        backgroundColor={'white'}
        height={'100%'}
        borderRadius={'4px'}
      >
        {!hasReportingToken &&
        userInfo?.currentAccount?.id &&
        userInfo.accounts ? (
          <Text
            px={'20px'}
            mt={6}
            fontSize={'20px'}
            lineHeight={'46px'}
            color={'red.500'}
          >
            Please, add a reporting token for your Offset account "
            {userInfo?.currentAccount?.name}"
          </Text>
        ) : (
          <>
            <Flex
              px={'24px'}
              mt={8}
              gridGap={2}
              justifyContent={'space-between'}
            >
              <Heading
                lineHeight={'46px'}
                fontSize={'25px'}
                fontFamily={'Beatrice-Regular'}
              >
                Test Console
              </Heading>
              <Flex>
                <Select
                  placeholder="Select GPT Model..."
                  value={models?.find((c) => c.value === selectedModel)}
                  options={models?.map((model) => ({
                    value: model.value,
                    label: model.label,
                  }))}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: '14px',
                      minWidth: '410px',
                      minHeight: '40px',
                    }),
                    option: (styles, { isSelected, isFocused }) => ({
                      ...styles,
                      backgroundColor: isSelected
                        ? '#FFB9AC'
                        : isFocused
                          ? '#E7EAEC'
                          : 'white',
                      color: '#2A2F56',
                      fontSize: '14px',
                    }),
                  }}
                  onChange={(e) => {
                    if (e?.value && e?.value != selectedModel) {
                      setSelectedModel(e?.value);
                    }
                  }}
                />
                <Button
                  fontSize={'14px'}
                  disabled={
                    isLoading ||
                    isLoadingId.length > 0 ||
                    (entityType == EntitiesEnum.CUSTOMERS
                      ? customerQueries
                      : entityType == EntitiesEnum.ORDERS
                        ? orderQueries
                        : entityType == EntitiesEnum.PRODUCTS
                          ? productQueries
                          : feedbackQueries
                    ).length == 0
                  }
                  width={'120px'}
                  ml={'12px'}
                  onClick={handleRunAllQueries}
                >
                  Test All
                </Button>
              </Flex>
            </Flex>
            <Box w={'100%'} overflowX="scroll">
              {isLoading ? (
                <Flex
                  h={'300px'}
                  w={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Spinner color="orange.100" />
                </Flex>
              ) : (
                <TableContainer
                  mt={6}
                  mx={'24px'}
                  borderWidth={'1px'}
                  borderColor={'#DEE2E6'}
                  borderRadius={'6px'}
                >
                  <Table colorScheme={'blackAlpha'} size="md">
                    <Thead backgroundColor={'#F3F4F5'}>
                      <Tr>
                        <Th
                          pl={'24px'}
                          py={'16px'}
                          textColor={'#4F5268'}
                          fontSize={'13px'}
                        >
                          Id
                        </Th>
                        <Th textColor={'#4F5268'} fontSize={'13px'}>
                          Model
                        </Th>
                        <Th textColor={'#4F5268'} fontSize={'13px'}>
                          Query
                        </Th>
                        <Th textColor={'#4F5268'} fontSize={'13px'}>
                          Result
                        </Th>
                        <Th textColor={'#4F5268'} fontSize={'13px'}>
                          Corrected
                        </Th>
                        <Th textColor={'#4F5268'} fontSize={'13px'}>
                          Pass
                        </Th>
                        <Th textColor={'#4F5268'} fontSize={'13px'}>
                          Actions
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {(entityType == EntitiesEnum.CUSTOMERS
                        ? customerQueries
                        : entityType == EntitiesEnum.ORDERS
                          ? orderQueries
                          : entityType == EntitiesEnum.PRODUCTS
                            ? productQueries
                            : feedbackQueries
                      ).map((query: QueryData, i) => (
                        <Tr key={i}>
                          <Td
                            pl={'24px'}
                            width={'4%'}
                            textColor={'#2A2F56'}
                            fontSize={'14px'}
                          >
                            {query.id}
                          </Td>
                          <Td width={'8%'}>
                            <Text textColor={'#2A2F56'} fontSize={'14px'}>
                              {query.model
                                ? 'GPT-' +
                                  (query.model == '3'
                                    ? '3.5'
                                    : query.model == '4'
                                      ? '4.5'
                                      : query.model)
                                : ''}
                            </Text>
                          </Td>
                          <Td width={'24%'}>
                            <Text textColor={'#2A2F56'} fontSize={'14px'}>
                              {query.query_text}
                            </Text>
                          </Td>
                          <Td width={'24%'}>
                            <Text textColor={'#2A2F56'} fontSize={'14px'}>
                              {query.result_text}
                            </Text>
                          </Td>
                          <Td width={'24%'}>
                            <Text textColor={'#2A2F56'} fontSize={'14px'}>
                              {query.corrected_text}
                            </Text>
                          </Td>
                          <Td width={'8%'} pl={'32px'}>
                            {query.is_pass == 0 && <FaTimes color="red" />}
                            {query.is_pass == 1 && <FaCheck color="green" />}
                          </Td>
                          <Td width={'8%'} pl={'48px'}>
                            {isLoadingId == query.id ? (
                              <Spinner
                                color="orange.100"
                                width={'16px'}
                                height={'16px'}
                              />
                            ) : (
                              <FaRegPlayCircle
                                color="#4F5268"
                                cursor={'pointer'}
                                onClick={() => handleRunQuery(query)}
                              />
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default TestConsole;
