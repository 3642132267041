import React, { useState } from 'react';
import { Flex, Input, Text, Image, Box } from '@chakra-ui/react';
import { useSpring, animated } from '@react-spring/web';

type CustomInputProps = {
  type: string;
  placeholder: string;
  autoFocus: boolean;
  width: number;
  handleOnChange: (value: string) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const CustomInput = ({
  type,
  placeholder,
  autoFocus,
  width,
  handleOnChange,
  handleOnKeyDown,
}: CustomInputProps) => {
  const [inputContent, setInputContent] = useState<string>('');
  const [isInputFocus, setIsInputFocus] = useState<boolean>(true);
  const [isFloatLabel, setIsFloatLabel] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [props, set] = useSpring(
    () =>
      autoFocus
        ? {
            from: {
              transform: 'translate(0, 10px)',
              scale: '1',
            },
            to: {
              transform: 'translate(-7.5%, 0px)',
              scale: '0.85',
            },
            config: { duration: 150 },
          }
        : {
            from: {
              transform: 'translate(0, 10px)',
              scale: '1',
            },
            to: {
              transform: 'translate(0, 10px)',
              scale: '1',
            },
            config: { duration: 150 },
          },
    [],
  );

  const handleOnFocus = async () => {
    setIsInputFocus(true);
    if (!inputContent) {
      setIsFloatLabel(true);
      showFloatAnimation(true);
    }
  };

  const handleOnBlur = async () => {
    setIsInputFocus(false);
    if (!inputContent) {
      setIsFloatLabel(false);
      showFloatAnimation(false);
    }
  };

  const showFloatAnimation = async (isFloat: boolean) => {
    if (isFloat) {
      set({
        from: {
          transform: 'translate(0, 10px)',
          scale: '1',
        },
        to: {
          transform: 'translate(-7.5%, 0px)',
          scale: '0.85',
        },
      });
    } else {
      set({
        from: {
          transform: 'translate(-7.5%, 0px)',
          scale: '0.85',
        },
        to: {
          transform: 'translate(0, 10px)',
          scale: '1',
        },
      });
    }
  };

  const handleInputChange = async (value: string) => {
    if (value.length > 0) {
      if (type == 'code') {
        if (/^[0-9]\d*$/.test(value)) {
          dealWithInputChange(value);
        }
      } else {
        dealWithInputChange(value);
      }
    } else {
      dealWithInputChange(value);
    }
  };

  const dealWithInputChange = async (value: string) => {
    setInputContent(value);
    handleOnChange(value);

    if (value.length > 0) {
      if (!isFloatLabel) {
        setIsFloatLabel(true);
        showFloatAnimation(true);
      }
    } else {
      if (!isInputFocus && isFloatLabel) {
        setIsFloatLabel(false);
        showFloatAnimation(false);
      }
    }
  };

  const handleInputKeyDown = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key == 'Delete') {
      setInputContent('');
    }
    if (handleOnKeyDown) {
      handleOnKeyDown(e);
    }
  };

  return (
    <Flex pt={3}>
      <Input
        autoFocus={autoFocus}
        type={
          type == 'password' && showPassword
            ? 'text'
            : type == 'code'
              ? 'text'
              : type
        }
        variant="offset"
        fontSize={'15px'}
        height={'60px'}
        borderColor={'white'}
        _focus={{ boxShadow: '0 0 12px #BE4837' }}
        pt={4}
        value={inputContent}
        onChange={(e) => handleInputChange(e.target.value)}
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        onKeyDown={(e) => handleInputKeyDown(e)}
      />
      <Flex
        pl={'17px'}
        mt={'8px'}
        position={'absolute'}
        justifyContent={'center'}
        pointerEvents={'none'}
      >
        <animated.div style={props}>
          <Text
            fontSize={'14px'}
            textColor={isFloatLabel ? '#75788F' : '#2B2F53'}
          >
            {placeholder}
          </Text>
        </animated.div>
      </Flex>
      {type == 'password' && (
        <Box
          position={'absolute'}
          ml={width - 36 + 'px'}
          mt={'17px'}
          cursor={'pointer'}
          onClick={(e) => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <Image width={'24px'} src="/eye_off.png" />
          ) : (
            <Image width={'24px'} src="/eye.png" />
          )}
        </Box>
      )}
    </Flex>
  );
};

export default CustomInput;
