import { Flex, Image, Text } from '@chakra-ui/react';
import { useToken } from '../../services/auth';
import { Link as RouterDomLink } from 'react-router-dom';
import { EntitiesEnum } from '../../services/queryBuilder';

type NavBarProps = {
  removeToken: () => void;
  entityType: EntitiesEnum | null;
  paramName: string;
};

const NavBar = ({ removeToken, entityType, paramName }: NavBarProps) => {
  const { userInfo } = useToken();

  return (
    <Flex my={'13px'} gridGap={2}>
      {(entityType != EntitiesEnum.HOME || paramName == 'tests') && (
        <Flex minWidth={'1000px'}>
          <Flex
            height={'38px'}
            px={'16px'}
            alignItems={'center'}
            backgroundColor={
              entityType == EntitiesEnum.CUSTOMERS ? '#2A2F56' : ''
            }
            borderRadius={'6px'}
            as={RouterDomLink}
            to={`/${paramName}/${EntitiesEnum.CUSTOMERS}`}
          >
            <Image
              width={'16px'}
              height={'16px'}
              src={
                entityType == EntitiesEnum.CUSTOMERS
                  ? '/ic_customers_sel.png'
                  : '/ic_customers.png'
              }
            />
            <Text
              fontSize={'16px'}
              textColor={
                entityType == EntitiesEnum.CUSTOMERS ? 'white' : '#DDE0FB'
              }
              ml={'6px'}
            >
              Customers
            </Text>
          </Flex>
          {/* <Flex
            height={"38px"}
            px={"16px"}
            alignItems={"center"}
            backgroundColor={entityType == EntitiesEnum.ORDERS ? "#2A2F56" : ""}
            borderRadius={"6px"}
            as={RouterDomLink}
            to={`/${paramName}/${EntitiesEnum.ORDERS}`}
          >
            <Image
              width={"16px"}
              height={"16px"}
              src={entityType == EntitiesEnum.ORDERS ? "/ic_orders_sel.png" : "/ic_orders.png"}  
            />
            <Text
              fontSize={"16px"}
              textColor={entityType == EntitiesEnum.ORDERS ? "white" : "#DDE0FB"}
              ml={"6px"}
            >
              Orders
            </Text>
          </Flex>
          <Flex
            height={"38px"}
            px={"16px"}
            alignItems={"center"}
            backgroundColor={entityType == EntitiesEnum.PRODUCTS ? "#2A2F56" : ""}
            borderRadius={"6px"}
            as={RouterDomLink}
            to={`/${paramName}/${EntitiesEnum.PRODUCTS}`}
          >
            <Image
              width={"16px"}
              height={"16px"}
              src={entityType == EntitiesEnum.PRODUCTS ? "/ic_products_sel.png" : "/ic_products.png"}  
            />
            <Text
              fontSize={"16px"}
              textColor={entityType == EntitiesEnum.PRODUCTS ? "white" : "#DDE0FB"}
              ml={"6px"}
            >
              Products
            </Text>
          </Flex> */}
          {paramName != 'tests' && (
            <Flex
              height={'38px'}
              px={'16px'}
              alignItems={'center'}
              backgroundColor={
                entityType == EntitiesEnum.PURCHASES ? '#2A2F56' : ''
              }
              borderRadius={'6px'}
              as={RouterDomLink}
              to={`/${paramName}/${EntitiesEnum.PURCHASES}`}
            >
              <Image
                width={'16px'}
                height={'16px'}
                src={
                  entityType == EntitiesEnum.PURCHASES
                    ? '/ic_customers_sel.png'
                    : '/ic_customers.png'
                }
              />
              <Text
                fontSize={'16px'}
                textColor={
                  entityType == EntitiesEnum.PURCHASES ? 'white' : '#DDE0FB'
                }
                ml={'6px'}
              >
                Purchases
              </Text>
            </Flex>
          )}
          {/* {paramName != "tests" && userInfo && userInfo?.profile.email.endsWith("@interactivelabs.co") && (
            <Flex
              height={"38px"}
              px={"16px"}
              alignItems={"center"}
              backgroundColor={entityType == EntitiesEnum.ALGOLIA_CUSTOMERS ? "#2A2F56" : ""}
              borderRadius={"6px"}
              as={RouterDomLink}
              to={`/${paramName}/${EntitiesEnum.ALGOLIA_CUSTOMERS}`}
            >
              <Image
                width={"16px"}
                height={"16px"}
                src={entityType == EntitiesEnum.ALGOLIA_CUSTOMERS ? "/ic_customers_sel.png" : "/ic_customers.png"}  
              />
              <Text
                fontSize={"16px"}
                textColor={entityType == EntitiesEnum.ALGOLIA_CUSTOMERS ? "white" : "#DDE0FB"}
                ml={"6px"}
              >
                Algolia - C
              </Text>
            </Flex>
          )}
          {paramName != "tests" && userInfo && userInfo?.profile.email.endsWith("@interactivelabs.co") && (
            <Flex
              height={"38px"}
              px={"16px"}
              alignItems={"center"}
              backgroundColor={entityType == EntitiesEnum.ALGOLIA_ORDERS ? "#2A2F56" : ""}
              borderRadius={"6px"}
              as={RouterDomLink}
              to={`/${paramName}/${EntitiesEnum.ALGOLIA_ORDERS}`}
            >
              <Image
                width={"16px"}
                height={"16px"}
                src={entityType == EntitiesEnum.ORDERS ? "/ic_orders_sel.png" : "/ic_orders.png"}  
              />
              <Text
                fontSize={"16px"}
                textColor={entityType == EntitiesEnum.ALGOLIA_ORDERS ? "white" : "#DDE0FB"}
                ml={"6px"}
              >
                Algolia - O
              </Text>
            </Flex>
          )}
          {paramName != "tests" && userInfo && userInfo?.profile.email.endsWith("@interactivelabs.co") && (
            <Flex
              height={"38px"}
              px={"16px"}
              alignItems={"center"}
              backgroundColor={entityType == EntitiesEnum.ALGOLIA_PRODUCTS ? "#2A2F56" : ""}
              borderRadius={"6px"}
              as={RouterDomLink}
              to={`/${paramName}/${EntitiesEnum.ALGOLIA_PRODUCTS}`}
            >
              <Image
                width={"16px"}
                height={"16px"}
                src={entityType == EntitiesEnum.PRODUCTS ? "/ic_products_sel.png" : "/ic_products.png"}  
              />
              <Text
                fontSize={"16px"}
                textColor={entityType == EntitiesEnum.ALGOLIA_PRODUCTS ? "white" : "#DDE0FB"}
                ml={"6px"}
              >
                Algolia - P
              </Text>
            </Flex>
          )} */}
          {paramName == 'tests' && (
            <Flex
              height={'38px'}
              px={'16px'}
              alignItems={'center'}
              backgroundColor={!entityType ? '#2A2F56' : ''}
              borderRadius={'6px'}
              as={RouterDomLink}
              to={`/${paramName}/feedback`}
            >
              <Image
                width={'16px'}
                height={'16px'}
                src={!entityType ? '/ic_products_sel.png' : '/ic_products.png'}
              />
              <Text
                fontSize={'16px'}
                textColor={!entityType ? 'white' : '#DDE0FB'}
                ml={'6px'}
              >
                Feedback
              </Text>
            </Flex>
          )}
        </Flex>
      )}
      <Flex
        width={'100%'}
        height={'38px'}
        alignItems={'center'}
        justifyContent={'end'}
      >
        {userInfo?.profile && (
          <Text
            width={'32px'}
            height={'32px'}
            minWidth={'32px'}
            fontSize={'12px'}
            color={'white'}
            backgroundColor={'#5F7357'}
            borderRadius={'16px'}
            textAlign={'center'}
            pt={'8px'}
            cursor={'pointer'}
            onClick={removeToken}
          >
            {userInfo?.profile.first_name.charAt(0)}
            {userInfo?.profile.last_name.charAt(0)}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;
