import { http } from './http';
import { EntitiesEnum } from './queryBuilder';

export async function getLastUpdate(
  entity: EntitiesEnum,
  accountId: string,
): Promise<Date | null> {
  try {
    const { data } = await http.get<{ last_timestamp: string }>(
      `/api/v1/sync-service-control/${entity}/${accountId}`,
    );
    if (data.last_timestamp) {
      return new Date(data.last_timestamp);
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
}
