import { AxiosResponse } from 'axios';
import { RuleRowType } from '../components/QueryBuilder/RuleRow';
import { UserInfo } from './auth';
import { http } from './http';

export type Customer = {
  id: string;
  remote_id: string;
  first_name: string;
  last_name: string;
  email: string;
  credits: string;
  status: string;
  state: string;
  phone: string;
  order_count: number;
  order_total: string;
  last_order_date: string;
  last_order: string;
  last_update: string;
  birthday: string;
  first_purchase_date: string;
  signup_email_last_sent: string;
  active_account: boolean;
  address: string;
  address_2: string;
  agreed_to_mailing_list: boolean;
  agreed_to_terms_privacy: boolean;
  assigned_owner: string;
  billing_address: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
  children: boolean;
  city: string;
  clubs: string;
  tags: string;
  comments: string;
  company: string;
  consent_date: string;
  consent_time: string;
  created: string;
  created_by: string;
  created_date: string;
  created_time: string;
  crm_customer_record: string;
  customer_source: string;
  customer_type: string;
  data_vip_pickup: boolean;
  email_alt: string;
  email_spouse: string;
  facebook: string;
  first_name_spouse: string;
  groups: string;
  hobbies: string;
  inactive_date: string;
  interests: string;
  job_title: string;
  last_name_spouse: string;
  mailing_address: string;
  mailing_address_2: string;
  mailing_city: string;
  mailing_company: string;
  mailing_country: string;
  mailing_state: string;
  mailing_zip: string;
  mail_marketing_opt_in: string;
  mail_marketing_opt_in_update: string;
  marketing: boolean;
  marketing_opt_in: boolean;
  mobile: string;
  mobile_phone: string;
  nickname: string;
  pet: string;
  phone_call_opt_in: boolean;
  pinterest: string;
  primary_phone: string;
  receive_text_message: boolean;
  receive_text_messages: boolean;
  referral_code: string;
  referral_note: string;
  referral_source: string;
  salutation: string;
  salutation_spouse: string;
  score: number;
  sports: string;
  spouse_phone: string;
  sub_group: string;
  suffix: string;
  suffix_spouse: string;
  temporary_customer_notes: string;
  title: string;
  twitter: string;
  user_facebook: string;
  user_instagram: string;
  user_linkedin: string;
  user_pinterest: string;
  user_twitter: string;
  vip_pickup: string;
  website_source: string;
  work: string;
  work_experience: string;
  work_phone: string;
  zip: string;
};

export type Group = {
  id: string;
  remote_id: string;
  name: string;
  status: string;
  sort: number;
  allocation_message: string;
  allocation_start: string;
  allocation_end: string;
  discount: number;
  free_shipping: boolean;
};

export type Club = {
  id: string;
  remote_id: string;
  name: string;
  sort: number;
  club_confirmation_message: string;
  club_info_1: string;
  club_info_2: string;
  club_info_3: string;
  discount: number;
  free_shipping: boolean;
};

export type Tag = {
  id: string;
  name: string;
};

export type CustomerResData = {
  data: Customer[];
  total: number;
  pageIndex: number;
  pageSize: number;
  sortIndex: number;
  sortType: number;
  isExport?: boolean;
};

export type SimpleCustomerResData = {
  simpleData: string[];
  total: number;
  pageIndex: number;
  pageSize: number;
  isExport?: boolean;
};

export async function getGroups(): Promise<Group[]> {
  try {
    const { data } = await http.get<Record<number, Group>>(
      `/api/v1/customers/api-remote/groups`,
    );

    return Object.values(data).map((group) => group);
  } catch (error) {
    throw error;
  }
}

export async function getClubs(): Promise<Club[]> {
  try {
    const { data } = await http.get<Record<number, Club>>(
      `/api/v1/customers/api-remote/clubs`,
    );

    return Object.values(data).map((club) => club);
  } catch (error) {
    throw error;
  }
}

export async function getTags(accountId: string): Promise<Tag[]> {
  try {
    const { data } = await http.get<Record<number, Tag>>(
      `/api/v1/tags/${accountId}`,
    );

    return Object.values(data).map((tag) => tag);
  } catch (error) {
    throw error;
  }
}

export async function getCustomerTypes(): Promise<string[]> {
  try {
    const { data } = await http.get<Record<number, string>>(
      `/api/v1/customers/api-remote/customer-types`,
    );

    return Object.values(data).map((element) => element);
  } catch (error) {
    throw error;
  }
}

export async function getCustomerSources(): Promise<string[]> {
  try {
    const { data } = await http.get<Record<number, string>>(
      `/api/v1/customers/api-remote/customer-sources`,
    );

    return Object.values(data).map((element) => element);
  } catch (error) {
    throw error;
  }
}

export async function removeFromGroups(customerId: string): Promise<void> {
  try {
    await http.delete(`/api/v1/customers/api-remote/${customerId}/groups`);
  } catch (error) {
    throw error;
  }
}

export async function addCustomerToGroup(
  customerId: string,
  groupId: number,
): Promise<void> {
  try {
    await http.put(
      `/api/v1/customers/api-remote/${customerId}/groups/${groupId}`,
    );
  } catch (error) {
    throw error;
  }
}

export async function addCustomerNote(
  customerId: string,
  note: string,
  user: UserInfo,
): Promise<void> {
  try {
    await http.post(`/api/v1/customers/api-remote/${customerId}/notes`, {
      message: note,
      user_id: user.profile.id,
      user_name: user.profile.first_name + ' ' + user.profile.last_name,
    });
  } catch (error) {
    throw error;
  }
}

export async function addCustomerTag(
  customerId: string,
  tag: string,
): Promise<void> {
  try {
    await http.put(`/api/v1/customers/api-remote/${customerId}/tags/${tag}`);
  } catch (error) {
    throw error;
  }
}

export async function getCustomerById(id: string): Promise<Customer> {
  try {
    const { data } = await http.get<Customer>(`/api/v1/customers/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateCustomer(
  customer: Partial<Customer>,
): Promise<void> {
  try {
    await http.put(`/api/v1/customers/api-remote/customers`, customer);
  } catch (error) {
    throw error;
  }
}

export async function filterAddCustomersGroup(
  accountId: string,
  groupId: number,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-add-customers-group/${accountId}/${groupId}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterMoveCustomersGroup(
  accountId: string,
  groupId: number,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-move-customers-group/${accountId}/${groupId}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterAddCustomersNote(
  accountId: string,
  note: string,
  user: UserInfo,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-add-customers-note/${accountId}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
        message: note,
        user_id: user.profile.id,
        user_name: user.profile.first_name + ' ' + user.profile.last_name,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterAddCustomersTag(
  accountId: string,
  tag: string,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-add-customers-tag/${accountId}/${tag}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterAddPurchasesGroup(
  accountId: string,
  groupId: number,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-add-purchases-group/${accountId}/${groupId}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterMovePurchasesGroup(
  accountId: string,
  groupId: number,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-move-purchases-group/${accountId}/${groupId}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterAddPurchasesNote(
  accountId: string,
  note: string,
  user: UserInfo,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-add-purchases-note/${accountId}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
        message: note,
        user_id: user.profile.id,
        user_name: user.profile.first_name + ' ' + user.profile.last_name,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function filterAddPurchasesTag(
  accountId: string,
  tag: string,
  rg: RuleRowType[][],
): Promise<void> {
  try {
    await http.post(
      `/api/v1/customers/api-remote/filter-add-purchases-tag/${accountId}/${tag}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
      },
    );
  } catch (error) {
    throw error;
  }
}

// These services use our own IL api
export async function getCustomersByRuleGroups(
  rg: RuleRowType[][],
  accountId?: string,
  page: {
    pageSize: number;
    pageIndex: number;
  } = {
    pageSize: 50,
    pageIndex: 1,
  },
  sortIndex?: number,
  sortType?: number,
  isExport = false,
): Promise<CustomerResData> {
  try {
    const { data } = await http.post<
      {
        ruleGroups: RuleRowType[][];
        notPurchased: number;
        isExport?: boolean;
      },
      AxiosResponse<CustomerResData>
    >(
      `/api/v1/customers/filter/${accountId}/${sortIndex ?? 0}/${sortType ?? 0}`,
      {
        ruleGroups: rg,
        notPurchased: 0,
        ...page,
        isExport,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
}

// These services use our own IL api
export async function getCustomersByFastQuery(
  accountId?: string,
  queryType?: string,
  page: {
    pageSize: number;
    pageIndex: number;
  } = {
    pageSize: 50,
    pageIndex: 1,
  },
  sortIndex?: number,
  sortType?: number,
  isExport = false,
): Promise<CustomerResData> {
  try {
    const { data } = await http.post<
      {
        isExport?: boolean;
      },
      AxiosResponse<CustomerResData>
    >(
      `/api/v1/customers/fast-query/${accountId}/${queryType}/${sortIndex ?? 0}/${sortType ?? 0}`,
      {
        ...page,
        isExport,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
}

// These services use our own IL api
export async function getPurchasesCustomersByRuleGroups(
  rg: RuleRowType[][],
  np: number,
  accountId?: string,
  page: {
    pageSize: number;
    pageIndex: number;
  } = {
    pageSize: 50,
    pageIndex: 1,
  },
  sortIndex?: number,
  sortType?: number,
  isExport = false,
): Promise<CustomerResData> {
  try {
    const { data } = await http.post<
      {
        ruleGroups: RuleRowType[][];
        notPurchased: number;
        isExport?: boolean;
      },
      AxiosResponse<CustomerResData>
    >(
      `/api/v1/customers/filter/${accountId}/${sortIndex ?? 0}/${sortType ?? 0}`,
      {
        ruleGroups: rg,
        notPurchased: np,
        ...page,
        isExport,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
}

export async function pullCustomersFromOffset(): Promise<void> {
  try {
    await http.get<Customer[]>(`/api/v1/customers/api-remote/get-customers`);
  } catch (error) {
    throw error;
  }
}

export async function pullLastUpdatedCustomersFromOffset(
  accountId: string | undefined,
): Promise<void> {
  try {
    await http.get<Customer[]>(
      `/api/v1/customers/api-remote/get-customers-last-update${
        accountId ? `?account_id=${accountId}` : ''
      }`,
    );
  } catch (error) {
    throw error;
  }
}
