import React, { useState } from 'react';
import {
  Flex,
  useColorModeValue,
  Image,
  Text,
  Link,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import SegmentList from './pages/SegmentList/SegmentList';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import { useToken } from './services/auth';
import SideBar from './components/SideBar/SideBar';
import CustomerSegment from './pages/Segment/CustomerSegment';
import ProductSegment from './pages/Segment/ProductSegment';
import OrderSegment from './pages/Segment/OrderSegment';
import { EntitiesEnum } from './services/queryBuilder';
import TestConsole from './pages/Test/TestConsole';
import { PAGE_INDEX_HOME } from './utils/constants';
import PurchasesSegment from './pages/Segment/PurchasesSegment';
import AlgoliaCustomerSegment from './pages/Segment/AlgoliaCustomerSegment';
import AlgoliaOrderSegment from './pages/Segment/AlgoliaOrderSegment';
import AlgoliaProductSegment from './pages/Segment/AlgoliaProductSegment';
import NavBar from './components/NavBar/NavBar';
import Landing from './pages/Segment/Landing';
import QueryResults from './pages/Segment/QueryResults';

export const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { token, setToken } = useToken();
  const [currentPageIndex, setCurrentPageIndex] =
    useState<number>(PAGE_INDEX_HOME);
  const formBackground = useColorModeValue('#505268', '#505268');

  const handleSignOut = () => {
    setToken(null);
    localStorage.removeItem('login_time');
  };

  const handleSetPageIndex = (pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
  };

  const handleSetLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  if (!token) {
    return (
      <BrowserRouter>
        <Flex direction={'column'}>
          <Flex height={'93.7vh'} minHeight={'720px'}>
            <Switch>
              <Route path="/forgot">
                <ForgotPassword />
              </Route>
              <Route path="/reset">
                <ResetPassword />
              </Route>
              <Route>
                <Login setToken={setToken} />
              </Route>
            </Switch>
          </Flex>
          <Flex alignItems={'center'} minHeight={'60px'} px={20}>
            <Flex minWidth={'160px'} flexDirection={'column'}>
              <Image src="/offset-logo-white.png" w={'80px'} />
            </Flex>
            <Flex minWidth={'220px'} width={'100%'} justifyContent={'end'}>
              <Link
                fontSize={'11px'}
                color={'#dde0fb'}
                _focus={{ boxShadow: 'none' }}
                href={'mailto:support@offsetpartners.com'}
              >
                support@offsetpartners.com
              </Link>
              <Text fontSize={'11px'} color={'#dde0fb'} mx={1.5}>
                /
              </Text>
              <Link
                fontSize={'11px'}
                color={'#dde0fb'}
                _focus={{ boxShadow: 'none' }}
                href={'https://www.offsetpartners.com/terms/'}
                target={'_blank'}
              >
                Terms & Conditions
              </Link>
              <Text fontSize={'11px'} color={'#dde0fb'} mx={1.5}>
                /
              </Text>
              <Link
                fontSize={'11px'}
                color={'#dde0fb'}
                _focus={{ boxShadow: 'none' }}
                href={'https://www.offsetpartners.com/privacy/'}
                target={'_blank'}
              >
                Privacy Policy
              </Link>
              <Text fontSize={'11px'} color={'#dde0fb'} mx={1.5}>
                /
              </Text>
              <Text fontSize={'11px'} color={'#dde0fb'}>
                © 2024
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Flex
        bg={formBackground}
        minHeight={'100vh'}
        textAlign="center"
        fontSize="xl"
      >
        <SideBar
          removeToken={() => handleSignOut()}
          pageIndex={currentPageIndex}
          setToken={setToken}
          setIsLoading={handleSetLoading}
        />
        <Flex direction={'column'} width={'100%'} pb={'24px'}>
          {isLoading ? (
            <Flex direction={'column'} height={'100%'} pr={'30px'}>
              <NavBar
                removeToken={() => handleSignOut()}
                entityType={EntitiesEnum.HOME}
                paramName={'segs'}
              />
              <Flex
                direction={'column'}
                backgroundColor={'white'}
                height={'100%'}
                borderRadius={'4px'}
              >
                <Box w={'100%'} mt={'46px'} overflowX="scroll">
                  <Flex
                    h={'300px'}
                    w={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Spinner color="orange.100" />
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          ) : (
            <Switch>
              <Route path="/segs/customer/:id">
                <CustomerSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/customer">
                <SegmentList
                  entityType={EntitiesEnum.CUSTOMERS}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/order/:id">
                <OrderSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/order">
                <SegmentList
                  entityType={EntitiesEnum.ORDERS}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/product/:id">
                <ProductSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/product">
                <SegmentList
                  entityType={EntitiesEnum.PRODUCTS}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/purchases/:id">
                <PurchasesSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/purchases">
                <SegmentList
                  entityType={EntitiesEnum.PURCHASES}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/algolia-customer">
                <AlgoliaCustomerSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/algolia-order">
                <AlgoliaOrderSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segs/algolia-product">
                <AlgoliaProductSegment
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/tests/customer">
                <TestConsole
                  entityType={EntitiesEnum.CUSTOMERS}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/tests/order">
                <TestConsole
                  entityType={EntitiesEnum.ORDERS}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/tests/product">
                <TestConsole
                  entityType={EntitiesEnum.PRODUCTS}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/tests/feedback">
                <TestConsole
                  entityType={null}
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/segment/query-results/:index">
                <QueryResults
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Route path="/home">
                <Landing
                  signOut={handleSignOut}
                  setPageIndex={handleSetPageIndex}
                />
              </Route>
              <Redirect from="*" to="/home" />
            </Switch>
          )}
        </Flex>
      </Flex>
    </BrowserRouter>
  );
};
