import { RuleRowType } from '../components/QueryBuilder/RuleRow';
import { http } from './http';
import { EntitiesEnum, getCustomFieldsByEntity } from './queryBuilder';

export type SegmentType = {
  id: string;
  name: string;
  ruleGroups: RuleRowType[][];
  entity_type: EntitiesEnum;
  account_id?: string;
  not_purchased: number;
};

export async function getSegments(
  currentAccountId: string | undefined,
  entityType: EntitiesEnum,
): Promise<SegmentType[]> {
  try {
    let url = '/api/v1/segments?version=' + new Date().getTime();

    if (currentAccountId) {
      url =
        `/api/v1/segments/account/${currentAccountId}/${entityType}?version=` +
        new Date().getTime();
    }

    const { data } = await http.get<SegmentType[]>(url);

    return data;
  } catch (error) {
    throw error;
  }
}

export async function getSegmentById(id: string): Promise<SegmentType> {
  try {
    const { data } = await http.get<SegmentType>(
      `/api/v1/segments/${id}?version=` + new Date().getTime(),
    );
    data.ruleGroups.forEach((e) => {
      e.forEach((e1) => {
        if (e1.field) {
          const field = getCustomFieldsByEntity(e1.field.entity).find(
            (customField) => customField.key === e1.field?.key,
          );
          if (field && field.asyncOptions) {
            field.options = e1.field.options;
            e1.field = field;
          }
        }
      });
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function createSegment(
  segment: SegmentType,
  currentAccountId: string,
): Promise<SegmentType> {
  try {
    const body = {
      name: segment.name,
      ruleGroups: segment.ruleGroups,
      entity_type: segment.entity_type,
      not_purchased: segment.not_purchased,
      account_id: currentAccountId,
    };
    const { data } = await http.post<SegmentType>(
      '/api/v1/segments',
      body as SegmentType,
    );

    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateSegment(segment: SegmentType): Promise<void> {
  try {
    await http.patch<SegmentType>(`/api/v1/segments/${segment.id}`, segment);
  } catch (error) {
    throw error;
  }
}

export async function deleteSegment(id: string): Promise<void> {
  try {
    await http.delete<SegmentType>(`/api/v1/segments/${id}`);
  } catch (error) {
    throw error;
  }
}
