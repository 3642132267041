import { Checkbox, Text } from '@chakra-ui/react';

const borderColor = '#CACDCF';
const controlColor = '#BE4837';

const defaultClasses = ({ controlRadius = '6px' }) => {
  return {
    h: '24px',
    w: 'fit-content',
    "span[class*='checkbox__control']:not([data-disabled])": {
      borderColor: borderColor,
      borderRadius: controlRadius,
      _checked: {
        bg: controlColor,
        borderColor: controlColor,
      },
      _focus: {
        boxShadow: `0 0 0 2px transparent`,
        _checked: {
          boxShadow: `0 0 0 2px transparent`,
        },
      },
    },
    _hover: {
      "span[class*='checkbox__control']:not([data-disabled])": {
        _after: {
          width: '24px',
          height: '24px',
          borderColor: controlColor,
        },
      },
    },
  };
};

export const CheckboxStyled = ({
  children = '',
  spacing = '1rem',
  ...props
}) => {
  const classes = defaultClasses({});

  return (
    <Checkbox
      spacing={spacing}
      sx={classes}
      {...props}
      iconColor={'white'}
      size={'lg'}
    >
      {children}
    </Checkbox>
  );
};

export const CheckboxNormal = ({
  children = '',
  spacing = '0.5rem',
  ...props
}) => {
  const classes = defaultClasses({});

  return (
    <Checkbox
      spacing={spacing}
      sx={classes}
      {...props}
      iconColor={'white'}
      size={'lg'}
      textColor={'black'}
    >
      <Text fontSize={'14px'}>{children}</Text>
    </Checkbox>
  );
};
